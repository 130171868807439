import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useLenis } from "@studio-freight/react-lenis";
import cx from "classnames";
import { AnimatePresence, motion, useWillChange } from "framer-motion";
import { IoArrowForwardSharp } from "react-icons/io5";

import { contacts, links } from "../data/links";

import * as styles from "./header.module.scss";

function Header() {
  const willChange = useWillChange();
  const [open, setOpen] = useState({
    status: false,
    animated: false,
  });

  const lenis = useLenis();

  useEffect(() => {
    if (lenis) {
      if (open.status) {
        lenis.stop();
      } else {
        lenis.start();
      }
    }
  }, [open.status]);

  return (
    <header className={styles.container}>
      <nav className={styles.wrap}>
        <Link className={styles.logo} to="/">
          MRC-club
        </Link>
        <div className={styles.theme}>{/* <Theme /> */}</div>

        <div className={styles.right}>
          <button
            className={cx(styles.menu, open.status && styles.close)}
            onClick={() =>
              setOpen((prev) => ({
                ...prev,
                status: !open.status,
                animated: true,
              }))
            }
            style={{ pointerEvents: open.animated ? "none" : "auto" }}
          >
            <div className={styles.content}>
              <span className={styles.background} />
              <span className={styles.label}>
                <AnimatePresence initial={false} mode="wait">
                  {open.status ? (
                    <motion.span
                      initial={{ y: "100%" }}
                      animate={{ y: "0%" }}
                      exit={{ y: "100%" }}
                      transition={{
                        type: "spring",
                        damping: 20,
                      }}
                      className={styles.span}
                      key={`close_label`}
                    >
                      Закрыть
                    </motion.span>
                  ) : (
                    <motion.span
                      initial={{ y: "100%" }}
                      animate={{ y: "0%" }}
                      exit={{ y: "100%" }}
                      transition={{
                        type: "spring",
                        damping: 20,
                      }}
                      className={styles.span}
                      key={`menu_label`}
                    >
                      Меню
                    </motion.span>
                  )}
                </AnimatePresence>
              </span>
            </div>
            <div className={styles.circlewrap}>
              <span className={styles.circle} />
              <div className={styles.lines}>
                <span className={styles.line} />
                <span className={styles.line} />
              </div>
            </div>
          </button>
        </div>
      </nav>
      <AnimatePresence initial={false}>
        {open.status && (
          <motion.div
            className={styles.navigation}
            initial={{
              clipPath: "inset(100% 0% 0%)",
            }}
            animate={{
              clipPath: "inset(0% 0% 0%)",
            }}
            exit={{
              clipPath: "inset(0% 0% 100%)",
            }}
            transition={{
              type: "spring",
              damping: 20,
            }}
            style={{
              willChange,
            }}
            onAnimationComplete={() =>
              setOpen((prev) => ({ ...prev, animated: false }))
            }
            key={`navigation`}
          >
            <div className={styles.background} />
            <Link
              className={cx(styles.logo, styles.white)}
              to="/"
              onClick={() =>
                setOpen((prev) => ({
                  ...prev,
                  status: false,
                  animated: true,
                }))
              }
            >
              MRC-club
            </Link>

            <div className={styles.content}>
              <div className={styles.links}>
                {links.map((item, index) => {
                  return (
                    <motion.div
                      initial={{ opacity: 0, y: 10 + index * 2 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                          delay: 0.3 + 0.075 * index,
                          duration: 0.6,
                          ease: [0.165, 0.84, 0.44, 1],
                        },
                      }}
                      exit={{ opacity: 0 }}
                      key={`link_${index}`}
                    >
                      <Link
                        to={item.href}
                        onClick={() =>
                          setOpen((prev) => ({
                            ...prev,
                            status: false,
                            animated: true,
                          }))
                        }
                        className="link-header"
                      >
                        <p className="label">{item.name}</p>
                        <IoArrowForwardSharp className="arrow" />
                      </Link>
                    </motion.div>
                  );
                })}
              </div>
              <div className={styles.contacts}>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: 1,
                    transition: {
                      delay: 0.5,
                      duration: 0.6,
                      ease: [0.165, 0.84, 0.44, 1],
                    },
                  }}
                  exit={{ opacity: 0 }}
                  className={styles.title}
                >
                  Контакты:
                </motion.p>
                {contacts.map((item, index) => {
                  return (
                    <motion.a
                      initial={{ opacity: 0, y: 10 + index * 2 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                          delay: 0.3 + 0.075 * index,
                          duration: 0.6,
                          ease: [0.165, 0.84, 0.44, 1],
                        },
                      }}
                      exit={{ opacity: 0 }}
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                      className="link-header"
                      key={`contact_${index}`}
                    >
                      <p className="label">{item.name}</p>
                      <IoArrowForwardSharp className="arrow" />
                    </motion.a>
                  );
                })}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}

export default Header;
