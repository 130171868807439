// extracted by mini-css-extract-plugin
export var action = "modalform-module--action--afbf4";
export var background = "modalform-module--background--2e999";
export var block = "modalform-module--block--80eb7";
export var box = "modalform-module--box--dea68";
export var checkbox = "modalform-module--checkbox--5b787";
export var checked = "modalform-module--checked--72127";
export var circle = "modalform-module--circle--f7acd";
export var circlewrap = "modalform-module--circlewrap--99909";
export var close = "modalform-module--close--f7780";
export var complete = "modalform-module--complete--36dd8";
export var container = "modalform-module--container--8335e";
export var content = "modalform-module--content--80d60";
export var desktop = "modalform-module--desktop--63c51";
export var form = "modalform-module--form--267cb";
export var head = "modalform-module--head--84bae";
export var hint = "modalform-module--hint--e0f5a";
export var icon = "modalform-module--icon--004f7";
export var inputs = "modalform-module--inputs--f8182";
export var item = "modalform-module--item--05c51";
export var label = "modalform-module--label--beff4";
export var line = "modalform-module--line--e3035";
export var lines = "modalform-module--lines--7c075";
export var logo = "modalform-module--logo--9cb0c";
export var span = "modalform-module--span--6cfb0";
export var submit = "modalform-module--submit--28bf4";
export var text = "modalform-module--text--f88c4";
export var visible = "modalform-module--visible--08e27";
export var wrap = "modalform-module--wrap--4835f";