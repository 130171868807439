import React, { createContext, useContext, useState, useEffect } from "react";
import { useLenis } from "@studio-freight/react-lenis";
import { useLocalStorage } from "react-use";

const StateContext = createContext({
  form: false,
  theme: false,
  setForm: () => {},
  setTheme: () => {},
});

export const ContextProvider = ({ children }) => {
  const [form, setForm] = useState({
    visible: false,
    complete: false,
    id: "",
    name: "",
    title: "",
  });
  const [theme, setTheme] = useLocalStorage("theme", "light");

  const lenis = useLenis();

  useEffect(() => {
    if (lenis) {
      if (form.visible) {
        lenis.stop();
      } else {
        lenis.start();
      }
    }
  }, [form.visible]);

  return (
    <StateContext.Provider
      value={{
        form,
        theme,
        setForm,
        setTheme,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
