export const backgroundVariants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 0.9,
    transition: { duration: 1, ease: [0.165, 0.84, 0.44, 1] },
  },
  exit: { opacity: 0, transition: { duration: 0.4 } },
};

export const blockVariants = {
  initial: { clipPath: "inset(100% 0% 0%)" },
  animate: {
    clipPath: "inset(0% 0% 0% 0%)",
    transition: { duration: 1, ease: [0.165, 0.84, 0.44, 1] },
  },
  exit: { clipPath: "inset(0% 0% 100%)", transition: { duration: 0.4 } },
};

export const textVariants = {
  initial: { opacity: 0, y: 5 },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.3,
      ease: [0.165, 0.84, 0.44, 1],
    },
  },
  exit: { opacity: 0 },
};

export const inputVariants = {
  initial: { y: 15, opacity: 0 },
  animate: (custom) => ({
    y: 0,
    opacity: 1,
    transition: {
      duration: custom.duration,
      delay: custom.delay,
      ease: [0.165, 0.84, 0.44, 1],
    },
  }),
  exit: { y: 15, opacity: 0 },
};
