import React, { useState } from "react";
import { useStateContext } from "components/context";
import { AnimatePresence, motion } from "framer-motion";
import { useWindowSize } from "react-use";
import { IoCheckmarkSharp } from "react-icons/io5";
import { Link } from "gatsby";
import cx from "classnames";
import {
  backgroundVariants,
  blockVariants,
  inputVariants,
  textVariants,
} from "./animate";

import * as styles from "./modalform.module.scss";

function Modalform() {
  const { form, setForm } = useStateContext();
  const { height } = useWindowSize();

  const [isLoading, setIsLoading] = useState(false);
  const [contact, setContact] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    agreement: true,
    complete: false,
    status: "success",
  });

  const activeSubmite =
    contact.name !== "" &&
    (contact.phone || contact.email) !== "" &&
    contact.agreement;

  function closeModal() {
    setContact({
      name: "",
      phone: "",
      email: "",
      message: "",
      agreement: true,
      complete: false,
      status: "success",
    });
    setForm({ ...form, visible: false });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //     setIsLoading(true);

    //     // Ваш код для отправки данных формы на сервер
    //     axios
    //       .post(apiUrl, {
    //         form_name: form.name,
    //         user_name: contact.name,
    //         user_phone: contact.phone,
    //         user_email: contact.email,
    //         user_message: contact.message,
    //       })
    //       .then(function () {
    //         // Отправляем отчет о форме в яндекс метрику
    //         window.ym(76220497, "reachGoal", formNameWithSlug);

    //         setContact((prev) => ({ ...prev, complete: true }));
    //       })
    //       .catch((err) => console.log(err));

    //     // Очистка полей формы после отправки
  };

  return (
    <AnimatePresence initial={false}>
      {form.visible && (
        <section
          className={styles.container}
          style={{ height: height }}
          key={`callback_container`}
        >
          <motion.div
            variants={backgroundVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={styles.background}
            onClick={closeModal}
          />
          <motion.div
            variants={blockVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className={styles.block}
            data-lenis-prevent
          >
            <div
              className={cx(
                styles.form,
                contact.complete &&
                  contact.status === "success" &&
                  styles.complete
              )}
            >
              <Link className={styles.logo} to="/" onClick={closeModal}>
                MRC-club
              </Link>
              <button className={styles.close} onClick={closeModal}>
                <div className={styles.content}>
                  <span className={styles.label}>
                    <span className={styles.span}>Закрыть</span>
                  </span>
                </div>
                <div className={styles.circlewrap}>
                  <span className={styles.circle} />
                  <div className={styles.lines}>
                    <span className={styles.line} />
                    <span className={styles.line} />
                  </div>
                </div>
              </button>

              <form
                className={styles.wrap}
                onSubmit={handleSubmit}
                id={`callback_form`}
              >
                <div className={styles.head}>
                  <AnimatePresence mode="popLayout">
                    {contact.complete ? (
                      <motion.h3
                        key={`title_complete`}
                        variants={textVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition="transition"
                      >
                        {contact.status === "success"
                          ? "Спасибо за заявку"
                          : "Что-то пошло не так..."}
                      </motion.h3>
                    ) : (
                      <motion.h3
                        key={`title`}
                        variants={textVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition="transition"
                      >
                        {form.title}
                      </motion.h3>
                    )}
                  </AnimatePresence>
                </div>
                <div className={styles.inputs}>
                  <div className={styles.item}>
                    <div
                      className={cx(
                        styles.hint,
                        contact.name > 0 && styles.visible
                      )}
                    >
                      <span className={styles.text}>Имя</span>
                    </div>
                    <motion.input
                      variants={inputVariants}
                      custom={{ delay: 0.4, duration: 1 }}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition="transition"
                      required={false}
                      autoComplete="off"
                      value={contact.name}
                      disabled={contact.complete || isLoading}
                      placeholder="Как к Вам обращаться"
                      name="user_name"
                      onChange={(e) =>
                        setContact((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className={styles.item}>
                    <div
                      className={cx(
                        styles.hint,
                        contact.phone > 0 && styles.visible
                      )}
                    >
                      <span className={styles.text}>Телефон</span>
                    </div>

                    <motion.input
                      variants={inputVariants}
                      custom={{ delay: 0.45, duration: 1 }}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition="transition"
                      autoComplete="off"
                      value={contact.phone}
                      disabled={contact.complete || isLoading}
                      placeholder="Введите номер телефона"
                      name="user_phone"
                      onChange={(e) =>
                        setContact((prev) => ({
                          ...prev,
                          phone: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className={styles.item}>
                    <div
                      className={cx(
                        styles.hint,
                        contact.email > 0 && styles.visible
                      )}
                    >
                      <span className={styles.text}>Почта</span>
                    </div>
                    <motion.input
                      variants={inputVariants}
                      custom={{ delay: 0.5, duration: 1 }}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition="transition"
                      required={false}
                      autoComplete="off"
                      value={contact.email}
                      disabled={contact.complete || isLoading}
                      placeholder="Введите email"
                      name="user_social"
                      onChange={(e) =>
                        setContact((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                    />
                  </div>

                  <div className={styles.item}>
                    <div
                      className={cx(
                        styles.hint,
                        contact.message > 0 && styles.visible
                      )}
                    >
                      <span className={styles.text}>Сообщение</span>
                    </div>
                    <motion.textarea
                      variants={inputVariants}
                      custom={{ delay: 0.55, duration: 1 }}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                      transition="transition"
                      required={false}
                      autoComplete="off"
                      rows={5}
                      value={contact.message}
                      disabled={contact.complete || isLoading}
                      placeholder="Введите сообщение или вопрос"
                      name="user_message"
                      onChange={(e) =>
                        setContact((prev) => ({
                          ...prev,
                          message: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>

                <div className={styles.action}>
                  <motion.button
                    variants={inputVariants}
                    custom={{ delay: 0.6, duration: 1 }}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition="transition"
                    className={cx(
                      styles.checkbox,
                      contact.agreement && styles.checked
                    )}
                    type="button"
                    onClick={() =>
                      setContact((prev) => ({
                        ...prev,
                        agreement: !prev.agreement,
                      }))
                    }
                  >
                    <div className={styles.box}>
                      <IoCheckmarkSharp className={styles.icon} />
                    </div>
                    <p className={styles.label}>
                      Я согласен(-на) на обработку моих персональных данных
                    </p>
                  </motion.button>

                  <motion.div
                    variants={inputVariants}
                    custom={{ delay: 0.7, duration: 1 }}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    transition="transition"
                    className={styles.submit}
                  >
                    {contact.complete ? (
                      contact.status === "success" ? (
                        <button
                          type="button"
                          className={cx("button red full-width")}
                          onClick={() => setForm({ ...form, visible: false })}
                        >
                          Вернуться к сайту
                        </button>
                      ) : (
                        <button
                          className={cx(
                            "button-modal-form",
                            !activeSubmite && "noactive",
                            isLoading && "loading"
                          )}
                          type="submit"
                          id="callback_form"
                        >
                          Повторить отправку
                        </button>
                      )
                    ) : (
                      <button
                        className={cx(
                          "button-modal-form",
                          !activeSubmite && "noactive",
                          isLoading && "loading"
                        )}
                        disabled={!activeSubmite}
                        type="submit"
                        id="callback_form"
                      >
                        {isLoading
                          ? "Отправляем..."
                          : activeSubmite
                          ? "Отправить"
                          : "Заполните поля"}
                      </button>
                    )}
                  </motion.div>
                </div>
              </form>
            </div>
          </motion.div>
        </section>
      )}
    </AnimatePresence>
  );
}

export default Modalform;
